import { onAuthStateChanged, User } from "firebase/auth";
import { ReactNode, useEffect, useState } from "react";
import { createContext } from "react";
import { auth } from "../firebase";

export const AuthContext = createContext<User | null>(null);

export default function AuthContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [currentUser, setCurrentUser] = useState<any>({});
  useEffect(() => {
    const unsubAuthStateChange = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
    return () => {
      unsubAuthStateChange();
    };
  }, []);

  return (
    <AuthContext.Provider value={currentUser}>{children}</AuthContext.Provider>
  );
}
