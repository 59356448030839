import React from "react";
import QuestionTile from "./question_tile";
import SummaryTile from "./summary_tile";
import QnaModel from "../models/qna_model";
import QnaInterface from "../models/qna_model";

function QnATile({
  qna,
  onGenerationComplete,
  onQuestionSelected,
}: {
  qna: QnaModel;
  onGenerationComplete: (value: QnaInterface) => void;
  onQuestionSelected: (value: string) => void;
}) {
  return (
    <div className="qna-tile-container pt-10">
      <div className="flex flex-col gap-1.5 max-w-3xl w-full min-w-300 px-5 py-7 bg-primary-25 border border-primary-100 rounded-md">
        <QuestionTile qna={qna.question} />
        <SummaryTile
          qna={qna}
          onSummaryGenerationComplete={onGenerationComplete}
          onQuestionSelected={onQuestionSelected}
        />
      </div>
    </div>
  );
}

export default QnATile;
