import QnaInterface from "../models/qna_model";
import QnaModel from "../models/qna_model";

interface Action {
  type: "generate" | "display" | "error";
  payload: any | null;
}

export default function summaryReducer(state: QnaInterface, action: Action) {
  const { type } = action;
  const { payload } = action;

  switch (type) {
    case "generate":
      return {
        ...state,
        isLoading: true,
      };
    case "display":
      return {
        ...state,
        isLoading: false,
        answer: payload.answer,
        related_questions: payload.fun_facts,
      };
    case "error":
      return {
        ...state,
        isLoading: false,
        answer: null,
        error: payload,
      };
    default:
      return state;
  }
}
