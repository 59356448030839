import "./App.css";
import "./style.scss";
import Home from "./pages/home";
import Login from "./pages/login";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import Signup from "./pages/signup";
import { useContext } from "react";
import { AuthContext } from "./context/auth_context";
import { isMobile } from "react-device-detect";
import MobileHome from "./pages/mobile_home";

function App() {
  const currentUser = useContext(AuthContext);

  function ProtectedRoute({ children }: { children: JSX.Element | null }) {
    const history = useHistory();
    if (currentUser == null) {
      history.replace("/login");
      return null;
    }
    return children;
  }
  if (isMobile) {
    window.location.replace("https://linktr.ee/labaapp");
    return null;
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <ProtectedRoute>
            <Home />
          </ProtectedRoute>
        </Route>
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/signup">
          <Signup />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
