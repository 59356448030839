import React, { useCallback, useContext, useRef } from "react";
import InputField from "../components/input_field";
import Navbar from "../components/nav_bar";
import QnATile from "../components/qna_tile";
import { DocumentData } from "firebase/firestore";
import { AuthContext } from "../context/auth_context";
import QnaInterface from "../models/qna_model";
import useFeedLoader from "../reducers/use_feed_loader";
import QueryTimeline from "../components/query_timeline";
import QuestionTile from "../components/question_tile";
import RelatedQuestionTile from "../components/related_question_tile";
import useRelatedQuestionLoader from "../reducers/use_related_question_loader";

export default function Home() {
  const [lastDocumentFetched, setLastDocumentFetched] =
    React.useState<DocumentData | null>(null);
  const [newQuestion, setNewQuestion] = React.useState<QnaInterface | null>(
    null
  );
  const currentUser = useContext(AuthContext);
  const { questions, nextDocumentToFetch, loading, error } = useFeedLoader(
    lastDocumentFetched,
    currentUser,
    newQuestion
  );

  const {
    loading_related_questions,
    related_question_has_error,
    related_question_list,
  } = useRelatedQuestionLoader();

  //Need to fix cuz currently can't ask new questions
  //1. It seems like this is our message when it's changed we need to rebuild the UI might use a different query we don't need to re-run the whole fetch but we need to update the UI and re-render the questions
  // const [qna_list, setQnaList] = React.useState<Array<QnaInterface>>([]);
  // const [hasReachedEnd, setHasReachedEnd] = React.useState(false);

  const observer = useRef<IntersectionObserver>();
  const topQuestionElementRef = useCallback(
    (node: any) => {
      if (loading) return;
      if (observer.current) observer.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && nextDocumentToFetch) {
          setLastDocumentFetched(nextDocumentToFetch);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, nextDocumentToFetch]
  );
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const feedContainerRef = useRef<HTMLDivElement>(null);

  function onValueEntered(question: string) {
    setNewQuestion({
      question: question,
      answer: null,
      isLoading: true,
      error: null,
      created_at: Date.now(),
      related_questions: [],
    });
    scrollToBottom();
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        // inline: "nearest",
      });
    }, 0);
  };

  const scrollToBottomCallback = useCallback(() => {
    scrollToBottom();
  }, []);

  return (
    <div className="bg-white">
      <Navbar />
      <div className="h-screen w-full flex flex-row">
        <div
          ref={feedContainerRef}
          className="h-full sticky overflow-y-auto flex flex-col-reverse flex-1 items-center justify-items-center px-3 w-full pb-48 pt-8"
        >
          {loading && questions.length == 0 && (
            <div>Loading feed of questions</div>
          )}
          {questions.map(function (qna, index) {
            if (questions.length === index + 1) {
              return (
                <div className="w-full" key={index} ref={topQuestionElementRef}>
                  <QnATile
                    qna={qna}
                    key={`${index} ${qna.created_at}`}
                    onGenerationComplete={(value) => {
                      const justAnswered = !questions[index].answer;
                      questions[index] = value;
                      if (justAnswered) {
                        scrollToBottomCallback();
                      }
                    }}
                    onQuestionSelected={(value) => onValueEntered(value)}
                  />
                </div>
              );
            } else if (index === 0) {
              return (
                <div className="w-full" ref={messagesEndRef}>
                  <QnATile
                    qna={qna}
                    key={`${index} ${qna.created_at} ${qna.question}`}
                    onGenerationComplete={(value) => {
                      const justAnswered = !questions[index].answer;
                      questions[index] = value;
                      if (justAnswered) {
                        scrollToBottomCallback();
                      }
                    }}
                    onQuestionSelected={(value) => onValueEntered(value)}
                  />
                </div>
              );
            }
            return (
              <QnATile
                qna={qna}
                key={`${index} ${qna.created_at} ${qna.question}`}
                onGenerationComplete={(value) => {
                  const justAnswered = !questions[index].answer;
                  questions[index] = value;
                  if (justAnswered) {
                    scrollToBottomCallback();
                  }
                }}
                onQuestionSelected={(value) => onValueEntered(value)}
              />
            );
          })}
          <InputField
            onSubmit={onValueEntered}
            onTap={() => scrollToBottomCallback()}
          />
        </div>
        {questions.length > 0 && (
          <div className="w-96 pr-4 pb-2 pt-16 bg-transparent z-20 h-auto hidden lg:block">
            <div className="flex flex-col bg-transparent rounded-lg bg-red-500 h-full w-full gap-2">
              <h4 className="font-laba text-lg font-bold pl-4 pt-4">
                Related Questions
              </h4>

              <div className="h-full w-full overflow-scroll gap-2 flex flex-col">
                {!loading_related_questions &&
                  !related_question_has_error &&
                  related_question_list.map(function (question, index) {
                    return (
                      <RelatedQuestionTile
                        key={index}
                        question={question}
                        onQuestionSelected={(value) => onValueEntered(value)}
                      />
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

// let lastDocumentFetched: any = null;
// let lastDocumentFetched: any = null;

// try {
//   const stream_query = query(
//     collection(db, `user_queries/${currentUser.uid}/queries`)
//   );
//   const unsub = onSnapshot(stream_query, (querySnapshot) => {
//     const tempList: QnaInterface[] = [];
//     querySnapshot.forEach((doc) => {
//       const data = doc.data();
//       if (data) {
//         const newQuestion = {
//           question: data["question"],
//           answer: data["answer"],
//           isLoading: false,
//           error: null,
//           created_at: data["created_at"],
//           related_questions: data["related_questions"],
//         };
//         tempList.push(newQuestion);
//       }
//     });
//     setQnaList([...qna_list, ...tempList]);
//     tempList.splice(0, tempList.length);
//   });
//   return () => {
//     unsub();
//   };
// } catch (e) {}

// const fetch_queries = async () => {
//   if (currentUser) {
//     try {
//       let queries_collection: Query<DocumentData, DocumentData>;
//       if (state.lastDocumentFetched) {
//         queries_collection = query(
//           collection(db, `user_queries/${currentUser.uid}/queries`),
//           orderBy("created_at", "desc"),
//           limit(9),
//           startAfter(state.lastDocumentFetched)
//         );
//       } else {
//         queries_collection = query(
//           collection(db, `user_queries/${currentUser.uid}/queries`),
//           orderBy("created_at", "desc"),
//           limit(9)
//         );
//       }
//       const queries_snapshot = await getDocs(queries_collection);

//       // if (queries_snapshot.empty && state.lastDocumentFetched) {
//       //   // feedContainerRef.current?.removeEventListener("scroll", loadOnScroll);
//       //   setHasReachedEnd(true);
//       // } else {
//       // }

//       const temp_list: Array<QnaInterface> = [];
//       queries_snapshot.docs.map((doc) => {
//         const data = doc.data();
//         const newQuestion = {
//           question: data["question"],
//           answer: data["answer"],
//           isLoading: false,
//           error: null,
//           created_at: data["created_at"],
//           related_questions: data["related_questions"],
//         };
//         temp_list.push(newQuestion);
//       });

//       setQnaList(
//         [...temp_list, ...qna_list].sort(
//           (a, b) => a.created_at - b.created_at
//         )
//       );

//       // if (!state.lastDocumentFetched) {
//       //   scrollToBottom();
//       // }
//       dispatch({ type: "load", payload: null });
//       dispatch({
//         type: "display",
//         payload: queries_snapshot.docs[queries_snapshot.docs.length - 1],
//       });
//     } catch (e) {}
//   }
// };

// function loadOnScroll() {
//   if (
//     (feedContainerRef.current?.scrollTop ?? -1) == 0 &&
//     state.lastDocumentFetched
//   ) {
//     // fetch_queries();
//   } else if (
//     (feedContainerRef.current?.scrollTop ?? -1) == 0 &&
//     !state.lastDocumentFetched
//   ) {
//   }
// }

// useEffect(() => {
//   if (!state.hasLoadedInitialQueries) {

//     fetch_queries();
//   }
//   feedContainerRef.current?.addEventListener("scroll", loadOnScroll);
//   return () => {
//     feedContainerRef.current?.removeEventListener("scroll", loadOnScroll);
//   };
// }, [state]);

// const updateQnaRefList = () => {
//   qna_list.forEach((qna, index) => {
//     if (qna_items_ref[index] == null) {
//       qna_items_ref[index] = React.createRef<HTMLDivElement>();
//     }
//   });
// };

// const scrollTo = (id: number) => {
//   qna_items_ref[id].current?.scrollIntoView();
// };

// const feedContainerRef = useRef<HTMLDivElement>(null);
// let qna_items_ref: Array<React.RefObject<HTMLDivElement>>;
// const [state, dispatch] = useReducer(homeReducer, {
//   hasLoadedInitialQueries: false,
//   error: "",
//   lastDocumentFetched: null,
// });
