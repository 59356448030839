import React from "react";
import CircleAvatar from "./circle_avatar";
import logo from "../ace_logo.svg";

export default function Navbar() {
  return (
    <nav className="bg-primary-25 border-b border-gray100 w-full fixed top-0 z-50">
      <div className="w-full flex flex-wrap items-center justify-between py-3 px-8">
        <a href="./" className="flex items-center">
          <img src={logo} className="h-6 mr-2" alt="Ace Logo" />
          <span className="self-center font-laba font-black text-xl whitespace-nowrap">
            LABA
          </span>
        </a>
        <div className="flex flex-row items-center md:order-2 gap-3">
          <CircleAvatar />

          {/* <button
            type="button"
            className="lg:hidden w-8 h-8 text-gray-700 border border-gray-300 focus:outline-none font-medium rounded-lg text-sm p-1.5 text-center inline-flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
            <span className="sr-only">Icon description</span>
          </button> */}
        </div>
      </div>
    </nav>
  );
}
