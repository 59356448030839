import React, { useState, useLayoutEffect, useEffect, RefObject } from "react";
import QnaInterface from "../models/qna_model";

const useTruncatedElement = (ref: RefObject<HTMLParagraphElement>) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isReadingMore, setIsReadingMore] = useState(false);

  useLayoutEffect(() => {
    const { offsetHeight, scrollHeight } = ref.current || {};

    if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  }, [ref]);

  return {
    isTruncated,
    isReadingMore,
    setIsReadingMore,
  };
};

export default function QuestionTile({ qna }: { qna: string }) {
  const ref = React.useRef<HTMLParagraphElement>(null);
  const topOfQuestionRef = React.useRef<HTMLDivElement>(null);
  const { isTruncated, isReadingMore, setIsReadingMore } =
    useTruncatedElement(ref);

  useEffect(() => {
    if (isReadingMore) {
      topOfQuestionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  });

  function date_TO_String(date_in_number: number) {
    const date = new Date(date_in_number);
    // get the year, month, date, hours, and minutes seprately and append to the string.
    var date_String =
      date.getDate() +
      "/" +
      (date.getMonth() + 1) +
      "/" +
      date.getFullYear() +
      " " +
      +date.getHours() +
      ":" +
      +date.getMinutes();
    return date_String;
  }

  // const date = date_TO_String(qna.created_at);

  return (
    <div
      ref={topOfQuestionRef}
      // ${
      //   !isReadingMore && "sticky-question"
      // }
      className="question-tile-container px-6 py-3 bg-white drop-shadow-4xl border border-primary-100 sticky-question z-50"
    >
      {/* <div className="question-tile-info">
        <div className="question-info-leading-items">
          <p className="question-info-text">You asked:</p>
        </div>
        <p className="question-info-text">{date}</p>
      </div> */}
      <p
        className={`text-md font-laba font-bold ${
          !isReadingMore && "line-clamp-3"
        }`}
        ref={ref}
      >
        {qna}
      </p>
      {isTruncated && (
        <div
          className="read-more"
          onClick={() => {
            setIsReadingMore(!isReadingMore);
          }}
        >
          {isReadingMore ? "Read less" : "Read more"}
        </div>
      )}
    </div>
  );
}
