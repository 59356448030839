// import React from "react";
import { Link, useHistory } from "react-router-dom";
import logo from "../ace_logo.svg";
import { SyntheticEvent, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import { FirebaseError } from "firebase/app";

export default function Login() {
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (
    e: SyntheticEvent<HTMLFormElement, SubmitEvent>
  ) => {
    setError("");
    setLoading(true);
    e.preventDefault();
    const form = e.currentTarget;
    const email = (form[0] as HTMLInputElement).value;
    const password = (form[1] as HTMLInputElement).value;
    try {
      await signInWithEmailAndPassword(auth, email, password);
      history.replace("/");
    } catch (error) {
      if (error instanceof FirebaseError) {
        setError(error.message.replace("Firebase:", ""));
      } else {
        setError("Something went wrong! Please try again.");
      }
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col h-screen w-screen items-center justify-start gap-8 p-12">
      <div className="flex flex-col gap-2">
        <img src={logo} className="h-6 mr-3" alt="Ace Logo" />
        <h2 className="text-2xl font-laba font-bold text-black">
          Sign in to Laba
        </h2>
      </div>
      <div className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 md:p-8">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              placeholder="name@company.com"
              required
            />
          </div>
          <div>
            <div className="flex items-start">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Password
              </label>
            </div>

            <input
              type="password"
              name="password"
              id="password"
              placeholder="••••••••"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full text-white bg-primary-500 hover:bg-primary-900 focus:ring-4 focus:outline-none focus:ring-primary-500 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            {isLoading ? "Signing in..." : "Sign in"}
          </button>
          <div className="text-sm font-medium text-gray-500">
            Not registered?{" "}
            <Link to="/signup" className="text-primary-900 hover:underline">
              Create account
            </Link>
          </div>
          {error && <div>{error}</div>}
        </form>
      </div>
    </div>
  );
}
