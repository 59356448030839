import { useContext, useEffect, useReducer } from "react";
import SummarySkeleton from "./summary_skeleton";
import QnaInterface from "../models/qna_model";
import summaryReducer from "../reducers/sumarry_reducer";
import { addDoc, collection } from "firebase/firestore";
import { AuthContext } from "../context/auth_context";
import { db } from "../firebase";
import Markdown from "react-markdown";

export default function SummaryTile({
  qna,
  onSummaryGenerationComplete,
  onQuestionSelected,
}: {
  qna: QnaInterface;
  onSummaryGenerationComplete: (value: QnaInterface) => void;
  onQuestionSelected: (value: string) => void;
}) {
  const currentUser = useContext(AuthContext);
  const [state, dispatch] = useReducer(summaryReducer, qna);

  const getResponse = async () => {
    if (state.isLoading && currentUser) {
      try {
        const res = await fetch(
          "https://laba-backend-render.onrender.com/chat",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "laba-uid": currentUser.uid,
            },
            body: JSON.stringify({
              topic: qna.question,
            }),
          }
        );

        const response = await res.json();

        const fun_facts = await (
          await fetch("https://laba-backend-render.onrender.com/funfact", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "laba-uid": currentUser.uid,
            },
            body: JSON.stringify({
              topic: response,
            }),
          })
        ).json();
        if (response != undefined && fun_facts !== null) {
          let fun_facts_json: any;
          if (fun_facts !== undefined) {
            fun_facts_json = JSON.parse(fun_facts);
          }
          fun_facts_json.fun_facts.sort(function (a: string, b: string) {
            return a?.length - b?.length;
          });
          const payload = {
            answer: response,
            fun_facts: fun_facts_json.fun_facts,
          };
          try {
            await addDoc(
              collection(db, `user_queries/${currentUser?.uid}/queries`),
              {
                question: state.question,
                answer: response,
                created_at: state.created_at,
                related_questions: fun_facts_json.fun_facts,
              }
            );
            dispatch({ type: "display", payload: payload });
          } catch (e) {
            throw Error(
              "Our services are currently at capacity please wait a little"
            );
          }
        } else {
          throw Error("Laba is currently asleep!");
        }
      } catch (e) {
        dispatch({
          type: "error",
          payload: "Something went wrong, Please try again or contact support",
        });
      }
    }
  };

  useEffect(() => {
    if (!state.isLoading) {
      onSummaryGenerationComplete(state);
    }
  }, [state]);

  useEffect(() => {
    getResponse();
  }, []);

  return (
    // <div className="flex flex-col items-center w-full">
    //   <div className="w-full p-3">
    //     {state.isLoading && <SummarySkeleton />}
    //     <Markdown className="font-laba font-semibold">
    //       {!state.isLoading && !state.error && state.answer && state.imageUrl}
    //     </Markdown>
    //     <p>{!state.isLoading && state.error}</p>
    //   </div>
    <div className="flex flex-col items-center w-full">
      <div className="w-full p-3">
        {state.isLoading && <SummarySkeleton />}

        {state.imageUrl ? (
          <img
            src={state.imageUrl}
            alt="Image"
            className="max-w-full max-h-96 mb-3"
            style={{ maxWidth: "70%" }}
          />
        ) : (
          <Markdown className="font-laba font-semibold">
            {!state.isLoading && !state.error && state.answer}
          </Markdown>
        )}
        <p>{!state.isLoading && state.error}</p>
      </div>

      <div className="w-full">
        <ul
          className={`w-full flex flex-row gap-2 overflow-x-scroll no-scrollbar ${
            state.related_questions?.length > 0 && "mt-2"
          }`}
        >
          {state.related_questions?.map(function (
            value: string,
            index: number
          ) {
            return (
              <li
                key={index}
                className="flex flex-row gap-2 bg-primary-100 px-6 py-2 rounded-lg cursor-pointer"
                onClick={() =>
                  onQuestionSelected(
                    `Explain the history of the following fun fact: ${value}`
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M12.5 8.33332L16.6666 12.5M16.6666 12.5L12.5 16.6667M16.6666 12.5H11.3333C8.53303 12.5 7.1329 12.5 6.06334 11.955C5.12253 11.4757 4.35763 10.7108 3.87826 9.76994C3.33329 8.70038 3.33329 7.30025 3.33329 4.49999V3.33332"
                    stroke="#67285B"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <p className="font-laba font-medium text-sm text-primary-900 whitespace-nowrap">
                  {value}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
