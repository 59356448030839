import { ChangeEvent, useRef } from "react";

export default function InputField({
  onSubmit,
  onTap,
}: {
  onSubmit: (value: string) => void;
  onTap: () => void;
}) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  function onEnterPressed(event: any) {
    if (
      event.keyCode === 13 &&
      event.shiftKey === false &&
      event.metaKey === false
    ) {
      handleSubmit(event);
    }
  }

  function resizeInputField(element: ChangeEvent<HTMLTextAreaElement>) {
    // const scrollHeight = element.target.scrollHeight;
    if (textAreaRef.current) {
      textAreaRef.current.style.height = `auto`;
      const scrollHeight =
        element.target.scrollHeight > 270 ? 270 : element.target.scrollHeight;
      textAreaRef.current.style.height = `${scrollHeight}px`;
    }
  }
  function handleSubmit(event: any) {
    event.preventDefault();
    const textarea = textAreaRef.current;
    const message = textarea?.value;
    if (message !== undefined && textarea !== undefined && message !== "") {
      if (!/\S/.test(message)) {
        return;
      }
      onSubmit(message);
      if (textarea) {
        textarea.value = "";
        textAreaRef.current.blur();
        textAreaRef.current.style.height = `auto`;
      }
    }
  }

  function clearInputField() {
    const textarea = textAreaRef.current;
    if (textarea != undefined) {
      textarea.value = "";
      textarea.style.height = "auto";
    }
  }
  return (
    <div
      className="input-field-container w-11/12 lg:w-7/12 box-border border-primary-500 mx-2"
      onClick={onTap}
    >
      <textarea
        className="input-field focus:outline-none focus:border-transparent focus:ring-transparent text-sm"
        placeholder="Type your question"
        data-gramm="false"
        onKeyDown={onEnterPressed}
        onChange={(e) => resizeInputField(e)}
        ref={textAreaRef}
      />
      <div className="trailing-input-action-items">
        <div className="clear-button" onClick={clearInputField}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#67285B"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            />
          </svg>
        </div>
        <div className="send-button" onClick={handleSubmit}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#67285B"
            className="w-6 h-6"
          >
            <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
          </svg>
        </div>
      </div>
    </div>
    // <div
    //   className="input-field-container mx-2 lg:m-0 lg:w-[calc(100%-390px)] "
    //   onClick={onTap}
    // >
    //   <textarea
    //     className="input-field focus:outline-none focus:border-transparent focus:ring-transparent"
    //     placeholder="Type your question"
    //     data-gramm="false"
    //     onKeyDown={onEnterPressed}
    //     ref={textAreaRef}
    //   />
    //   <div className="trailing-input-action-items">
    //     <div className="clear-button" onClick={clearInputField}>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         strokeWidth={1.5}
    //         stroke="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path
    //           strokeLinecap="round"
    //           strokeLinejoin="round"
    //           d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
    //         />
    //       </svg>
    //     </div>
    //     <div className="send-button" onClick={handleSubmit}>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         viewBox="0 0 24 24"
    //         fill="currentColor"
    //         className="w-6 h-6"
    //       >
    //         <path d="M3.478 2.405a.75.75 0 00-.926.94l2.432 7.905H13.5a.75.75 0 010 1.5H4.984l-2.432 7.905a.75.75 0 00.926.94 60.519 60.519 0 0018.445-8.986.75.75 0 000-1.218A60.517 60.517 0 003.478 2.405z" />
    //       </svg>
    //     </div>
    //   </div>
    // </div>
  );
}
