export default function RelatedQuestionTile({
  question,
  onQuestionSelected,
}: {
  question: string;
  onQuestionSelected: (value: string) => void;
}) {
  return (
    <div
      className="flex flex-row bg-white rounded-md border border-primary-50 px-3 py-3 gap-2 cursor-pointer hover:bg-primary-50 hover:border hover:border-primary-100"
      onClick={() => onQuestionSelected(question)}
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          // width="20"
          // height="20"
          viewBox="0 0 20 20"
          fill="none"
          className="w-4 h-4"
        >
          <path
            d="M12.5 8.33332L16.6666 12.5M16.6666 12.5L12.5 16.6667M16.6666 12.5H11.3333C8.53303 12.5 7.1329 12.5 6.06334 11.955C5.12253 11.4757 4.35763 10.7108 3.87826 9.76994C3.33329 8.70038 3.33329 7.30025 3.33329 4.49999V3.33332"
            stroke="#67285B"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <p className="font-laba text-sm font-medium">{question}</p>
    </div>
  );
}
