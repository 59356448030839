// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration -- DEV
// const firebaseConfig = {
//   apiKey: "AIzaSyCTf2i8macT2rxOmG5y0CcX11DI0jcfrug",
//   authDomain: "laba-developement---dag.firebaseapp.com",
//   projectId: "laba-developement---dag",
//   storageBucket: "laba-developement---dag.appspot.com",
//   messagingSenderId: "495755745788",
//   appId: "1:495755745788:web:4d22b97e1a13ddac383a79",
// };

// Your web app's Firebase configuration -- PRODUCTION
const firebaseConfig = {
  apiKey: "AIzaSyCF4yGtZB1HDGNHX0y9XtKM7JIpkrs2jow",
  authDomain: "laba-school-system.firebaseapp.com",
  databaseURL:
    "https://laba-school-system-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "laba-school-system",
  storageBucket: "laba-school-system.appspot.com",
  messagingSenderId: "276480309087",
  appId: "1:276480309087:web:36470a4b703ed7e5afa8ea",
  measurementId: "G-NC0LQNL6TZ",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
