import { User } from "firebase/auth";
import {
  DocumentData,
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  Query,
  getDocs,
  getDoc,
  QuerySnapshot,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import QnaInterface from "../models/qna_model";

export default function useFeedLoader(
  lastDocumentFetched: DocumentData | null,
  currentUser: User | null,
  newQuestion: QnaInterface | null
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [questions, setQuestions] = useState<Array<QnaInterface>>([]);
  const [nextDocumentToFetch, setNextDocumentToFetch] =
    useState<DocumentData | null>(null);

  function getQuery(currentUser: User) {
    //What would happen if the page is null? It can be null for 2 reasons
    //1. When we're initially fetching
    //2. When we have no more documents to fetch?
    //3. It seems like we need to store the lastFetchedDocument and send it back for the next render
    return lastDocumentFetched
      ? query(
          collection(db, `user_queries/${currentUser.uid}/queries`),
          orderBy("created_at", "desc"),
          limit(5),
          startAfter(lastDocumentFetched)
        )
      : query(
          collection(db, `user_queries/${currentUser.uid}/queries`),
          orderBy("created_at", "desc"),
          limit(5)
        );
  }

  function getQuestionsFromSnapshot(
    queries_snapshot: QuerySnapshot<DocumentData, DocumentData>
  ) {
    const temp_list: Array<QnaInterface> = [];
    queries_snapshot.docs.map((doc) => {
      const data = doc.data();
      const newQuestion = {
        question: data["question"],
        answer: data["answer"],
        imageUrl: data["imageUrl"],
        isLoading: false,
        error: null,
        created_at: data["created_at"],
        related_questions: data["related_questions"],
      };
      temp_list.push(newQuestion);
    });
    return temp_list;
  }

  useEffect(() => {
    if (newQuestion) setQuestions([newQuestion, ...questions]);
  }, [newQuestion]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    if (currentUser) {
      try {
        getDocs(getQuery(currentUser)).then((queries_snapshot) => {
          const temp_list: Array<QnaInterface> =
            getQuestionsFromSnapshot(queries_snapshot);
          setQuestions((prevQuestions) => {
            return [...temp_list, ...prevQuestions].sort(
              (a, b) => b.created_at - a.created_at
            );
          });
          //Potential bug because what happens when there is none?
          setNextDocumentToFetch(
            queries_snapshot.docs[queries_snapshot.docs.length - 1]
          );
          setLoading(false);
        });
      } catch (e) {
        setError(true);
      }
    }
  }, [lastDocumentFetched]);
  return { loading, error, questions, nextDocumentToFetch };
}
