// import React from "react";
// import default_profile_pic from "../default_profile_pic.svg";
import { signOut } from "firebase/auth";
import { auth } from "../firebase";
function CircleAvatar() {
  return (
    <div
      className="flex flex-row gap-1 bg-white px-4 py-1.5 shadow-outline hover:shadow-hover rounded-full items-center cursor-pointer font-semibold text-sm transition-all duration-500"
      onClick={() => signOut(auth)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="w-4 h-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
        />
      </svg>
      Logout
    </div>
    // <div className="circle-avatar-container" onClick={() => signOut(auth)}>
    //   {/* <img src={default_profile_pic} /> */}

    // </div>
  );
}

export default CircleAvatar;
