import { User } from "firebase/auth";
import {
  DocumentData,
  collection,
  limit,
  orderBy,
  query,
  startAfter,
  Query,
  getDocs,
  getDoc,
  QuerySnapshot,
  doc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import QnaInterface from "../models/qna_model";

export default function useRelatedQuestionLoader() {
  const [loading_related_questions, setLoading] = useState(true);
  const [related_question_has_error, setError] = useState(false);
  const [related_question_list, setQuestions] = useState<Array<string>>([]);

  useEffect(() => {
    setLoading(true);
    setError(false);

    try {
      getDoc(doc(db, "related_questions", "primary")).then((docSnap) => {
        const data = docSnap.data();
        if (data) {
          const temp_list: Array<string> = data["questions"];
          setQuestions(temp_list);
          setLoading(false);
        }
      });
    } catch (e) {
      setError(true);
    }
  }, []);
  return {
    loading_related_questions,
    related_question_has_error,
    related_question_list,
  };
}
